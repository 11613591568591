import React from "react";
import "./App.css";
import Login from './login';
import { Route, Routes, useNavigate } from "react-router-dom";
import Home from "./Components/Home";
import "./login.css";

const Header = () => {

  const navigate = useNavigate();

  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signin" element={<Login />} />
      </Routes>
    </>
  );
};

export default Header;
