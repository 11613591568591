import React from "react";
import { useNavigate } from "react-router-dom";
import "./Home.css";
import { useRef } from "react";

const Home = () => {
  const navigate = useNavigate();

  const aboutRef = useRef(null);

  const homeRef = useRef(null);

  const handleAboutClick = () => {
    aboutRef.current.scrollIntoView({ behavior: "smooth" });
  };

  const handlehomeClick = () => {
    homeRef.current.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <header className="header sticky">
        <div className="logo">
          <img src="logo.png" alt="Hotels R Us International Ltd" />
        </div>
        <nav>
          <ul>
            <li className="home-header" onClick={handlehomeClick}>Home</li>
            <li className="home-header" onClick={handleAboutClick}>About</li>
            <button class="sign-in" onClick={() => navigate("/signin")}>
              Sign In
            </button>
          </ul>
        </nav>
      </header>
      <div>
        <div className="hero" ref={homeRef}>
          <h1>Explore Your Next Destination, with Hotels R Us</h1>
        </div>
      </div>
      <div className="features" ref={aboutRef}>
        <div className="feature-item">
          <h3>About Us</h3>
          <p>
            Launched in mid-2023, hotels R us consists of a team of industry
            veterans with over 200 years of experience across all aspects of
            travel, from sales and tech to marketing and finance. We aim to
            become to a market leading B2B company that specialises in
            affordable luxury, focusing on destinations predominantly in Europe,
            the Middle East and Far East.
            <br></br>
          </p>
          <p>
            We are exclusively collaborating with The Travel Network Group
            (TTNG), marketing holidays through a network of more than 200 remote
            agents, 1,200 standalone retail outlets, and members of the World
            Choice and Global Travel Group. We are also partnering with UK-based
            online travel agent Teletext.
          </p>
        </div>
      </div>
      <div>
        <footer>
          <div class="footer-content">
            <img src="logo.png" alt="Company Logo" />
            <h3>Hotels R Us International Ltd</h3>
            <p>71-75 Shelton Street, Covent Garden, London, WC2H 9JQ</p>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Home;
