import React from "react";
import "./login.css";

function LoginPage() {
  return (
    <>
      <div className="login-page-container">
        <div className="login-container">
          <div className="login-logo">
            <h2>Login</h2>
            <div className="logo-align">
              <img src="logo2.png" alt="Hotels R Us International Ltd" />
            </div>
          </div>
          <form>
            <div className="form-group">
              <label htmlFor="username">Username:</label>
              <input type="text" id="username" name="username" required />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password:</label>
              <input type="password" id="password" name="password" required />
            </div>
            <div className="button-login">
              <button type="submit">Login</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
export default LoginPage;
